<template>
        <div class="view">
          <v-breadcrumbs>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
              >Home</v-breadcrumbs-item
            >
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'labelGen', params: {} }">
            Label Gen
            </v-breadcrumbs-item>
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item :to="{ name: 'labelGenView', params: {} }">
              View Label Gen
            </v-breadcrumbs-item>
          </v-breadcrumbs>

          <v-card v-if="loaded">
            <v-card-title>
              View Label Gen
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    
          <tr v-if="labelGen.product_id">
            <td max-width="35%" class="text-subtitle-1">Product:</td>
            <td>{{ labelGen.product_id }}</td>
          </tr>
          
          <tr v-if="labelGen.brand">
            <td max-width="35%" class="text-subtitle-1">Brand:</td>
            <td>{{ labelGen.brand }}</td>
          </tr>
          
          <tr v-if="labelGen.inventory_id">
            <td max-width="35%" class="text-subtitle-1">Label Gen:</td>
            <td>{{ labelGen.inventory_id }}</td>
          </tr>
          
          <tr v-if="labelGen.prefix">
            <td max-width="35%" class="text-subtitle-1">Prefix:</td>
            <td>{{ labelGen.prefix }}</td>
          </tr>
          
          <tr v-if="labelGen.code">
            <td max-width="35%" class="text-subtitle-1">Code:</td>
            <td>{{ labelGen.code }}</td>
          </tr>
          
          <tr v-if="labelGen.weights">
            <td max-width="35%" class="text-subtitle-1">Weights:</td>
            <td>{{ labelGen.weights }}</td>
          </tr>
          
          <tr v-if="labelGen.stock_date">
            <td max-width="35%" class="text-subtitle-1">Stock Date:</td>
            <td>{{ labelGen.stock_date }}</td>
          </tr>
          
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <div class="d-flex justify-space-between ma-5">
            <v-btn @click="goBack">Back</v-btn>
            <v-btn @click="edit(id)" color="primary">Edit</v-btn>
            <span></span>
          </div>
      </div>
    </template>

    <script>
    import { getLabelGen } from "@/services/labelGen";
    

    export default {
      data() {
        return {
            id: '',
            labelGen: {},
            loaded: false,
            relations: {}
        }
      },
      methods : {
        goBack() {
          this.$router.go(-1);
        },
        edit(id) {
          this.$router.push({ name: "labelGenEdit", params: { id: id }});
        },
        setInstance() {
          this.setDependencies().then( () => {
            getLabelGen(this.id)
            .then((response) => {
              this.labelGen = response.data;
              this.loaded = true;
            })
            .catch( () => {
              this.addErrorMessage("Error loading labelGen");
              this.loaded = true;
            });
          })
        },
        async setDependencies() {
          return null;
        },
        getRelationText(id, relationProperty, relationAttribute) {
          const relationList = this.relations[relationProperty];
          if (relationList) {
            const relationObj = relationList.find(r => r.id == id);
            return relationObj[relationAttribute];
          } else {
            return "";
          }
        }
      },
      created() {
        this.id = this.$route.params.id;
        this.setInstance();
        this.$emit("showParent", false);
      }
    }
    </script>
    